<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-center">
              <h1 class="heading-1">Terms of Use</h1>
              <p class="sub-title">Last updated February 16, 2022</p> 
            </div>
          </v-col>
          <v-col cols="12">
            <div class="pt-4">
              <p class="heading-2 upper">TABLE OF CONTENTS</p>
              <ol>
                <a href="#1">
                  <li class="text upper">AGREEMENT TO TERMS</li>
                </a>
                <a href="#2">
                  <li class="text upper">INTELLECTUAL PROPERTY RIGHTS</li>
                </a>
                <a href="#3"
                  ><li class="text upper">USER REPRESENTATIONS</li></a
                >
                <a href="#4"><li class="text upper">USER REGISTRATION</li></a>
                <a href="#5"
                  ><li class="text upper">PROHIBITED ACTIVITIES</li></a
                >
                <a href="#6"
                  ><li class="text upper">USER GENERATED CONTRIBUTIONS</li></a
                >
                <a href="#7"
                  ><li class="text upper">CONTRIBUTION LICENSE</li></a
                >
                <a href="#8"><li class="text upper">SOCIAL MEDIA</li></a>
                <a href="#9"><li class="text upper">SUBMISSIONS</li></a>
                <a href="#10"
                  ><li class="text upper">
                    THIRD-PARTY WEBSITE AND CONTENT
                  </li></a
                >
                <a href="#11"><li class="text upper">ADVERTISERS</li></a>
                <a href="#12"><li class="text upper">SITE MANAGEMENT</li></a>
                <a href="#13"><li class="text upper">PRIVACY NOTICE</li></a>
                <a href="#14"
                  ><li class="text upper">COPYRIGHT INFRINGEMENTS</li></a
                >
                <a href="#15"
                  ><li class="text upper">TERM AND TERMINATION</li></a
                >
                <a href="#16"
                  ><li class="text upper">
                    MODIFICATIONS AND INTERRUPTIONS
                  </li></a
                >
                <a href="#17"><li class="text upper">GOVERNING LAW</li></a>
                <a href="#18"><li class="text upper">DISPUTE RESOLUTION</li></a>
                <a href="#19"><li class="text upper">CORRECTIONS</li></a>
                <a href="#20"><li class="text upper">DISCLAIMER</li></a>
                <a href="#21"
                  ><li class="text upper">LIMITATIONS OF LIABILITY</li></a
                >
                <a href="#22"><li class="text upper">INDEMNIFICATION</li></a>
                <a href="#23"><li class="text upper">USER DATA</li></a>
                <a href="#24"
                  ><li class="text upper">
                    ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </li></a
                >
                <a href="#25"
                  ><li class="text upper">CALIFORNIA USERS AND RESIDENTS</li></a
                >
                <a href="#26"><li class="text upper">MISCELLANEOUS</li></a>
                <a href="#27"><li class="text upper">CONTACT US</li></a>
              </ol>
            </div>

            <div class="pt-4">
              <a class="anchor" id="1"></a>
              <p class="heading-1">1. AGREEMENT TO TERMS</p>
              <p class="text">
                These Terms of Use constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                ("<span class="bold">you</span>") and Resumation LLC ("<span
                  class="bold"
                  >Company</span
                >"), concerning your access
                to and use of the https://www.resumation.net website as well as
                any other media form, media channel, mobile website or mobile
                application related, linked, or otherwise connected thereto
                (collectively, the “<span class="bold">Site</span>”). The
                Company is registered in South Dakota, United States and have
                the Company's registered office at 25 First Ave. SW STE A,
                Watertown, SD 57201. You agree that by accessing the Site, you
                have read, understood, and agreed to be bound by all of these
                Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
                USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND
                YOU MUST DISCONTINUE USE IMMEDIATELY.
              </p>
              <p class="text">
                Supplemental terms and conditions or documents that may be
                posted on the Site from time to time are hereby expressly
                incorporated herein by reference. The Company reserves the
                right, in the Company's sole discretion, to make changes or
                modifications to these Terms of Use at any time and for any
                reason. The Company will alert you about any changes by updating
                the “Last updated” date of these Terms of Use, and you waive any
                right to receive specific notice of each such change. Please
                ensure that you check the applicable Terms every time you use
                the Company's Site so that you understand which Terms apply. You
                will be subject to, and will be deemed to have been made aware
                of and to have accepted, the changes in any revised Terms of Use
                by your continued use of the Site after the date such revised
                Terms of Use are posted.
              </p>
              <p class="text">
                The information provided on the Site is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject the Company
                to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the
                Site from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.
              </p>
              <p class="text">
                The Site is not tailored to comply with industry-specific
                regulations (Health Insurance Portability and Accountability Act
                (HIPAA), Federal Information Security Management Act (FISMA),
                etc.), so if your interactions would be subjected to such laws,
                you may not use this Site. You may not use the Site in a way
                that would violate the Gramm-Leach-Bliley Act (GLBA).
              </p>
              <p class="text">
                The Site is intended for users who are at least 18 years old.
                Persons under the age of 18 are not permitted to use or register
                for the Site.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="2"></a>
              <p class="heading-1">2. INTELLECTUAL PROPERTY RIGHTS</p>
              <p class="text">
                Unless otherwise indicated, the Site is the Company's
                proprietary property and all source code, databases,
                functionality, software, website designs, audio, video, text,
                photographs, and graphics on the Site (collectively, the
                “Content”) and the trademarks, service marks, and logos
                contained therein (the “Marks”) are owned or controlled by the
                Company or licensed to us, and are protected by copyright and
                trademark laws and various other intellectual property rights
                and unfair competition laws of the United States, international
                copyright laws, and international conventions. The Content and
                the Marks are provided on the Site “AS IS” for your information
                and personal use only. Except as expressly provided in these
                Terms of Use, no part of the Site and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted,
                distributed, sold, licensed, or otherwise exploited for any
                commercial purpose whatsoever, without the Company's express
                prior written permission.
              </p>
              <p class="text">
                Provided that you are eligible to use the Site, you are granted
                a limited license to access and use the Site and to download or
                print a copy of any portion of the Content to which you have
                properly gained access solely for your personal, non-commercial
                use. The Company reserves all rights not expressly granted to
                you in and to the Site, the Content and the Marks.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="3"></a>
              <p class="heading-1">3. USER REPRESENTATIONS</p>
              <p class="text">
                By using the Site, you represent and warrant that: (1) all
                registration information you submit will be true, accurate,
                current, and complete; (2) you will maintain the accuracy of
                such information and promptly update such registration
                information as necessary; (3) you have the legal capacity and
                you agree to comply with these Terms of Use; (4) you are not a
                minor in the jurisdiction in which you reside; (5) you will not
                access the Site through automated or non-human means, whether
                through a bot, script, or otherwise; (6) you will not use the
                Site for any illegal or unauthorized purpose; and (7) your use
                of the Site will not violate any applicable law or regulation.
              </p>
              <p class="text">
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, The Company has the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Site (or any portion thereof).
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="4"></a>
              <p class="heading-1">4. USER REGISTRATION</p>
              <p class="text">
                You may be required to register with the Site. You agree to keep
                your password confidential and will be responsible for all use
                of your account and password. The Company reserves the right to
                remove, reclaim, or change a username you select if the Company
                determines, in the Company's sole discretion, that such username
                is inappropriate, obscene, or otherwise objectionable.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="5"></a>
              <p class="heading-1">5. PROHIBITED ACTIVITIES</p>
              <p class="text">
                You may not access or use the Site for any purpose other than
                that for which the Company makes the Site available. The Site
                may not be used in connection with any commercial endeavors
                except those that are specifically endorsed or approved by the
                Company.
              </p>
              <p class="text">As a user of the Site, you agree not to:</p>
              <ol class="text">
                <li>
                  Systematically retrieve data or other content from the Site to
                  create or compile, directly or indirectly, a collection,
                  compilation, database, or directory without written permission
                  from the Company.
                </li>
                <li>
                  Trick, defraud, or mislead the Company and other users,
                  especially in any attempt to learn sensitive account
                  information such as user passwords.
                </li>
                <li>
                  Circumvent, disable, or otherwise interfere with
                  security-related features of the Site, including features that
                  prevent or restrict the use or copying of any Content or
                  enforce limitations on the use of the Site and/or the Content
                  contained therein.
                </li>
                <li>
                  Disparage, tarnish, or otherwise harm, in the Company's
                  opinion, the Company and/or the Site.
                </li>
                <li>
                  Use any information obtained from the Site in order to harass,
                  abuse, or harm another person.
                </li>
                <li>
                  Make improper use of the Company's support services or submit
                  false reports of abuse or misconduct.
                </li>
                <li>
                  Use the Site in a manner inconsistent with any applicable laws
                  or regulations.
                </li>
                <li>
                  Engage in unauthorized framing of or linking to the Site.
                </li>
                <li>
                  Upload or transmit (or attempt to upload or to transmit)
                  viruses, Trojan horses, or other material, including excessive
                  use of capital letters and spamming (continuous posting of
                  repetitive text), that interferes with any party's
                  uninterrupted use and enjoyment of the Site or modifies,
                  impairs, disrupts, alters, or interferes with the use,
                  features, functions, operation, or maintenance of the Site.
                </li>
                <li>
                  Engage in any automated use of the system, such as using
                  scripts to send comments or messages, or using any data
                  mining, robots, or similar data gathering and extraction
                  tools.
                </li>
                <li>
                  Delete the copyright or other proprietary rights notice from
                  any Content.
                </li>
                <li>
                  Attempt to impersonate another user or person or use the
                  username of another user.
                </li>
                <li>
                  Upload or transmit (or attempt to upload or to transmit) any
                  material that acts as a passive or active information
                  collection or transmission mechanism, including without
                  limitation, clear graphics interchange formats (“gifs”), 1×1
                  pixels, web bugs, cookies, or other similar devices (sometimes
                  referred to as “spyware” or “passive collection mechanisms” or
                  “pcms”).
                </li>
                <li>
                  Interfere with, disrupt, or create an undue burden on the Site
                  or the networks or services connected to the Site.
                </li>
                <li>
                  Harass, annoy, intimidate, or threaten any of the Company's
                  employees or agents engaged in providing any portion of the
                  Site to you.
                </li>
                <li>
                  Attempt to bypass any measures of the Site designed to prevent
                  or restrict access to the Site, or any portion of the Site.
                </li>
                <li>
                  Copy or adapt the Site's software, including but not limited
                  to Flash, PHP, HTML, JavaScript, or other code.
                </li>
                <li>
                  Except as permitted by applicable law, decipher, decompile,
                  disassemble, or reverse engineer any of the software
                  comprising or in any way making up a part of the Site.
                </li>
                <li>
                  Except as may be the result of standard search engine or
                  Internet browser usage, use, launch, develop, or distribute
                  any automated system, including without limitation, any
                  spider, robot, cheat utility, scraper, or offline reader that
                  accesses the Site, or using or launching any unauthorized
                  script or other software.
                </li>
                <li>
                  Use a buying agent or purchasing agent to make purchases on
                  the Site.
                </li>
                <li>
                  Make any unauthorized use of the Site, including collecting
                  usernames and/or email addresses of users by electronic or
                  other means for the purpose of sending unsolicited email, or
                  creating user accounts by automated means or under false
                  pretenses.
                </li>
                <li>
                  Use the Site as part of any effort to compete with the Company
                  or otherwise use the Site and/or the Content for any
                  revenue-generating endeavor or commercial enterprise.
                </li>
                <li>
                  Use the Site to advertise or offer to sell goods and services.
                </li>
                <li>Sell or otherwise transfer your profile.</li>
                <li>Data Scraping</li>
              </ol>
              <br />
            </div>
            <div class="pt-4">
              <a class="anchor" id="6"></a>
              <p class="heading-1">6. USER GENERATED CONTRIBUTIONS</p>
              <p class="text">
                The Site does not offer users to submit or post content. The
                Company may provide you with the opportunity to create, submit,
                post, display, transmit, perform, publish, distribute, or
                broadcast content and materials to the Company or on the Site,
                including but not limited to text, writings, video, audio,
                photographs, graphics, comments, suggestions, or personal
                information or other material (collectively, "Contributions").
                Contributions may be viewable by other users of the Site and
                through third-party websites. As such, any Contributions you
                transmit may be treated in accordance with the Site Privacy
                Notice. When you create or make available any Contributions, you
                thereby represent and warrant that:
              </p>
              <ol class="text">
                <li>
                  The creation, distribution, transmission, public display, or
                  performance, and the accessing, downloading, or copying of
                  your Contributions do not and will not infringe the
                  proprietary rights, including but not limited to the
                  copyright, patent, trademark, trade secret, or moral rights of
                  any third party.
                </li>
                <li>
                  You are the creator and owner of or have the necessary
                  licenses, rights, consents, releases, and permissions to use
                  and to authorize us, the Site, and other users of the Site to
                  use your Contributions in any manner contemplated by the Site
                  and these Terms of Use.
                </li>
                <li>
                  You have the written consent, release, and/or permission of
                  each and every identifiable individual person in your
                  Contributions to use the name or likeness of each and every
                  such identifiable individual person to enable inclusion and
                  use of your Contributions in any manner contemplated by the
                  Site and these Terms of Use.
                </li>
                <li>
                  Your Contributions are not false, inaccurate, or misleading.
                </li>
                <li>
                  Your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of solicitation.
                </li>
                <li>
                  Your Contributions are not obscene, lewd, lascivious, filthy,
                  violent, harassing, libelous, slanderous, or otherwise
                  objectionable (as determined by us).
                </li>
                <li>
                  Your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.
                </li>
                <li>
                  Your Contributions are not used to harass or threaten (in the
                  legal sense of those terms) any other person and to promote
                  violence against a specific person or class of people.
                </li>
                <li>
                  Your Contributions do not violate any applicable law,
                  regulation, or rule.
                </li>
                <li>
                  Your Contributions do not violate the privacy or publicity
                  rights of any third party.
                </li>
                <li>
                  Your Contributions do not violate any applicable law
                  concerning child pornography, or otherwise intended to protect
                  the health or well-being of minors.
                </li>
                <li>
                  Your Contributions do not include any offensive comments that
                  are connected to race, national origin, gender, sexual
                  preference, or physical handicap.
                </li>
                <li>
                  Your Contributions do not otherwise violate, or link to
                  material that violates, any provision of these Terms of Use,
                  or any applicable law or regulation.
                </li>
              </ol>
              <br />
              <p class="text">
                Any use of the Site in violation of the foregoing violates these
                Terms of Use and may result in, among other things, termination
                or suspension of your rights to use the Site.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="7"></a>
              <p class="heading-1">7. CONTRIBUTION LICENSE</p>
              <p class="text">
                You and the Site agree that the Company may access, store,
                process, and use any information and personal data that you
                provide following the terms of the Privacy Notice and your
                choices (including settings).
              </p>
              <p class="text">
                By submitting suggestions or other feedback regarding the Site,
                you agree that the Company can use and share such feedback for
                any purpose without compensation to you.
              </p>
              <p class="text">
                The Company does not assert any ownership over your
                Contributions. You retain full ownership of all of your
                Contributions and any intellectual property rights or other
                proprietary rights associated with your Contributions. The
                Company is not liable for any statements or representations in
                your Contributions provided by you in any area on the Site. You
                are solely responsible for your Contributions to the Site and
                you expressly agree to exonerate the Company from any and all
                responsibility and to refrain from any legal action against the
                Company regarding your Contributions.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="8"></a>
              <p class="heading-1">8. SOCIAL MEDIA</p>
              <p class="text">
                As part of the functionality of the Site, you may link your
                account with online accounts you have with third-party service
                providers (each such account, a “Third-Party Account”) by
                either: (1) providing your Third-Party Account login information
                through the Site; or (2) allowing the Company to access your
                Third-Party Account, as is permitted under the applicable terms
                and conditions that govern your use of each Third-Party Account.
                You represent and warrant that you are entitled to disclose your
                Third-Party Account login information to the Company and/or
                grant the Company access to your Third-Party Account, without
                breach by you of any of the terms and conditions that govern
                your use of the applicable Third-Party Account, and without
                obligating the Company to pay any fees or making the Company
                subject to any usage limitations imposed by the third-party
                service provider of the Third-Party Account. By granting the
                Company access to any Third-Party Accounts, you understand that
                (1) The Company may access, make available, and store (if
                applicable) any content that you have provided to and stored in
                your Third-Party Account (the “Social Network Content”) so that
                it is available on and through the Site via your account,
                including without limitation any friend lists and (2) The
                Company may submit to and receive from your Third-Party Account
                additional information to the extent you are notified when you
                link your account with the Third-Party Account. Depending on the
                Third-Party Accounts you choose and subject to the privacy
                settings that you have set in such Third-Party Accounts,
                personally identifiable information that you post to your
                Third-Party Accounts may be available on and through your
                account on the Site. Please note that if a Third-Party Account
                or associated service becomes unavailable or the Company's
                access to such Third Party Account is terminated by the
                third-party service provider, then Social Network Content may no
                longer be available on and through the Site. You will have the
                ability to disable the connection between your account on the
                Site and your Third-Party Accounts at any time. PLEASE NOTE THAT
                YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
                ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY
                YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. The
                Company makes no effort to review any Social Network Content for
                any purpose, including but not limited to, for accuracy,
                legality, or non-infringement, and The Company is not
                responsible for any Social Network Content. You acknowledge and
                agree that The Company may access your email address book
                associated with a Third-Party Account and your contacts list
                stored on your mobile device or tablet computer solely for
                purposes of identifying and informing you of those contacts who
                have also registered to use the Site. You can deactivate the
                connection between the Site and your Third-Party Account by
                contacting the Company using the contact information below or
                through your account settings (if applicable). The Company will
                attempt to delete any information stored on the Company's
                servers that was obtained through such Third-Party Account,
                except the username and profile picture that become associated
                with your account.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="9"></a>
              <p class="heading-1">9. SUBMISSIONS</p>
              <p class="text">
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback, or other information regarding the
                Site ("Submissions") provided by you to the Company are
                non-confidential and shall become the Company's sole property.
                The Company shall own exclusive rights, including all
                intellectual property rights, and shall be entitled to the
                unrestricted use and dissemination of these Submissions for any
                lawful purpose, commercial or otherwise, without acknowledgment
                or compensation to you. You hereby waive all moral rights to any
                such Submissions, and you hereby warrant that any such
                Submissions are original with you or that you have the right to
                submit such Submissions. You agree there shall be no recourse
                against the Company for any alleged or actual infringement or
                misappropriation of any proprietary right in your Submissions.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="10"></a>
              <p class="heading-1">10. THIRD-PARTY WEBSITE AND CONTENT</p>
              <p class="text">
                The Site may contain (or you may be sent via the Site) links to
                other websites ("Third-Party Websites") as well as articles,
                photographs, text, graphics, pictures, designs, music, sound,
                video, information, applications, software, and other content or
                items belonging to or originating from third parties
                ("Third-Party Content"). Such Third-Party Websites and
                Third-Party Content are not investigated, monitored, or checked
                for accuracy, appropriateness, or completeness by us, and the
                Company is not responsible for any Third-Party Websites accessed
                through the Site or any Third-Party Content posted on, available
                through, or installed from the Site, including the content,
                accuracy, offensiveness, opinions, reliability, privacy
                practices, or other policies of or contained in the Third-Party
                Websites or the Third-Party Content. Inclusion of, linking to,
                or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or
                endorsement thereof by the Company. If you decide to leave the
                Site and access the Third-Party Websites or to use or install
                any Third-Party Content, you do so at your own risk, and you
                should be aware these Terms of Use no longer govern. You should
                review the applicable terms and policies, including privacy and
                data gathering practices, of any website to which you navigate
                from the Site or relating to any applications you use or install
                from the Site. Any purchases you make through Third-Party
                Websites will be through other websites and from other
                companies, and the Company takes no responsibility whatsoever in
                relation to such purchases which are exclusively between you and
                the applicable third party. You agree and acknowledge that The
                Company does not endorse the products or services offered on
                Third-Party Websites and you shall hold the Company harmless
                from any harm caused by your purchase of such products or
                services. Additionally, you shall hold the Company harmless from
                any losses sustained by you or harm caused to you relating to or
                resulting in any way from any Third-Party Content or any contact
                with Third-Party Websites.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="11"></a>
              <p class="heading-1">11. ADVERTISERS</p>
              <p class="text">
                The Company allows advertisers to display their advertisements
                and other information in certain areas of the Site, such as
                sidebar advertisements or banner advertisements. If you are an
                advertiser, you shall take full responsibility for any
                advertisements you place on the Site and any services provided
                on the Site or products sold through those advertisements.
                Further, as an advertiser, you warrant and represent that you
                possess all rights and authority to place advertisements on the
                Site, including, but not limited to, intellectual property
                rights, publicity rights, and contractual rights. The Company
                simply provides the space to place such advertisements, and the
                Company has no other relationship with advertisers.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="12"></a>
              <p class="heading-1">12. SITE MANAGEMENT</p>
              <p class="text">
                The Company reserves the right, but not the obligation, to: (1)
                monitor the Site for violations of these Terms of Use; (2) take
                appropriate legal action against anyone who, in the Company's
                sole discretion, violates the law or these Terms of Use,
                including without limitation, reporting such user to law
                enforcement authorities; (3) in the Company's sole discretion
                and without limitation, refuse, restrict access to, limit the
                availability of, or disable (to the extent technologically
                feasible) any of your Contributions or any portion thereof; (4)
                in the Company's sole discretion and without limitation, notice,
                or liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to the Company's systems; and (5) otherwise manage
                the Site in a manner designed to protect the Company's rights
                and property and to facilitate the proper functioning of the
                Site.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="13"></a>
              <p class="heading-1">13. PRIVACY NOTICE</p>
              <p class="text">
                The Company cares about data privacy and security. Please review
                the Company's Privacy Notice:
                <a
                  href="https://www.resumation.net/privacynotice"
                  target="_blank"
                  >https://www.resumation.net/privacynotice</a
                >. By using the Site, you agree to be bound by the Company's
                Privacy Notice, which is incorporated into these Terms of Use.
                Please be advised the Site is hosted in the United States. If
                you access the Site from any other region of the world with laws
                or other requirements governing personal data collection, use,
                or disclosure that differ from applicable laws in the United
                States, then through your continued use of the Site, you are
                transferring your data to the United States, and you agree to
                have your data transferred to and processed in the United
                States.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="14"></a>
              <p class="heading-1">14. COPYRIGHT INFRINGEMENTS</p>
              <p class="text">
                The Company respects the intellectual property rights of others.
                If you believe that any material available on or through the
                Site infringes upon any copyright you own or control, please
                immediately notify the Company using the contact information
                provided below (a “Notification”). A copy of your Notification
                will be sent to the person who posted or stored the material
                addressed in the Notification. Please be advised that pursuant
                to applicable law you may be held liable for damages if you make
                material misrepresentations in a Notification. Thus, if you are
                not sure that material located on or linked to by the Site
                infringes your copyright, you should consider first contacting
                an attorney.
              </p>
              <p class="text bold no-space">Chief Executive Officer</p>
              <p class="text bold no-space">Resumation LLC</p>
              <p class="text bold no-space">25 First Ave. SW STE A</p>
              <p class="text bold no-space">Watertown, SD 57201</p>
              <p class="text bold no-space">Phone: (605) 229-9056</p>
              <p class="text bold no-space">
                Email:
                <a href="mailto:dmca@resumation.net">dmca@resumation.net</a>
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="15"></a>
              <p class="heading-1">15. TERM AND TERMINATION</p>
              <p class="text">
                These Terms of Use shall remain in full force and effect while
                you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
                TERMS OF USE, THE COMPANY RESERVES THE RIGHT TO, IN THE
                COMPANY'S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
                ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP
                ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
                APPLICABLE LAW OR REGULATION. THE COMPANY MAY TERMINATE YOUR USE
                OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
                CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
                WARNING, IN THE COMPANY'S SOLE DISCRETION.
              </p>
              <p class="text">
                If the Company terminates or suspends your account for any
                reason, you are prohibited from registering and creating a new
                account under your name, a fake or borrowed name, or the name of
                any third party, even if you may be acting on behalf of the
                third party. In addition to terminating or suspending your
                account, The Company reserves the right to take appropriate
                legal action, including without limitation pursuing civil,
                criminal, and injunctive redress.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="16"></a>
              <p class="heading-1">16. MODIFICATIONS AND INTERRUPTIONS</p>
              <p class="text">
                The Company reserves the right to change, modify, or remove the
                contents of the Site at any time or for any reason at the
                Company's sole discretion without notice. However, The Company
                has no obligation to update any information on the Company's
                Site. The Company also reserves the right to modify or
                discontinue all or part of the Site without notice at any time.
                The Company will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Site.
              </p>
              <p class="text">
                The Company cannot guarantee the Site will be available at all
                times. The Company may experience hardware, software, or other
                problems or need to perform maintenance related to the Site,
                resulting in interruptions, delays, or errors. The Company
                reserves the right to change, revise, update, suspend,
                discontinue, or otherwise modify the Site at any time or for any
                reason without notice to you. You agree that The Company has no
                liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Site during any
                downtime or discontinuance of the Site. Nothing in these Terms
                of Use will be construed to obligate the Company to maintain and
                support the Site or to supply any corrections, updates, or
                releases in connection therewith.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="17"></a>
              <p class="heading-1">17. GOVERNING LAW</p>
              <p class="text">
                These Terms of Use and your use of the Site are governed by and
                construed in accordance with the laws of the State of South
                Dakota applicable to agreements made and to be entirely
                performed within the State of South Dakota, without regard to
                its conflict of law principles.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="18"></a>
              <p class="heading-1">18. DISPUTE RESOLUTION</p>
              <p class="heading-2">Informal Negotiations</p>
              <p class="text">
                To expedite resolution and control the cost of any dispute,
                controversy, or claim related to these Terms of Use (each
                "Dispute" and collectively, the “Disputes”) brought by either
                you or the Company (individually, a “Party” and collectively,
                the “Parties”), the Parties agree to first attempt to negotiate
                any Dispute (except those Disputes expressly provided below)
                informally for at least thirty (30) days before initiating
                arbitration. Such informal negotiations commence upon written
                notice from one Party to the other Party.
              </p>
              <p class="heading-2">Binding Arbitration</p>
              <p class="text">
                If the Parties are unable to resolve a Dispute through informal
                negotiations, the Dispute (except those Disputes expressly
                excluded below) will be finally and exclusively resolved through
                binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
                YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
                The arbitration shall be commenced and conducted under the
                Commercial Arbitration Rules of the American Arbitration
                Association ("AAA") and, where appropriate, the AAA's
                Supplementary Procedures for Consumer Related Disputes ("AAA
                Consumer Rules"), both of which are available at the AAA
                website: www.adr.org. Your arbitration fees and your share of
                arbitrator compensation shall be governed by the AAA Consumer
                Rules and, where appropriate, limited by the AAA Consumer Rules.
                If such costs are determined by the arbitrator to be excessive,
                The Company will pay all arbitration fees and expenses. The
                arbitration may be conducted in person, through the submission
                of documents, by phone, or online. The arbitrator will make a
                decision in writing, but need not provide a statement of reasons
                unless requested by either Party. The arbitrator must follow
                applicable law, and any award may be challenged if the
                arbitrator fails to do so. Except where otherwise required by
                the applicable AAA rules or applicable law, the arbitration will
                take place in Pennington, South Dakota. Except as otherwise
                provided herein, the Parties may litigate in court to compel
                arbitration, stay proceedings pending arbitration, or to
                confirm, modify, vacate, or enter judgment on the award entered
                by the arbitrator.
              </p>
              <p class="text">
                If for any reason, a Dispute proceeds in court rather than
                arbitration, the Dispute shall be commenced or prosecuted in the
                state and federal courts located in Pennington, South Dakota,
                and the Parties hereby consent to, and waive all defenses of
                lack of personal jurisdiction, and forum non conveniens with
                respect to venue and jurisdiction in such state and federal
                courts. Application of the United Nations Convention on
                Contracts for the International Sale of Goods and the Uniform
                Computer Information Transaction Act (UCITA) is excluded from
                these Terms of Use.
              </p>
              <p class="text">
                In no event shall any Dispute brought by either Party related in
                any way to the Site be commenced more than one (1) years after
                the cause of action arose. If this provision is found to be
                illegal or unenforceable, then neither Party will elect to
                arbitrate any Dispute falling within that portion of this
                provision found to be illegal or unenforceable, and such Dispute
                shall be decided by a court of competent jurisdiction within the
                courts listed for jurisdiction above, and the Parties agree to
                submit to the personal jurisdiction of that court.
              </p>
              <p class="heading-2">Restrictions</p>
              <p class="text">
                The Parties agree that any arbitration shall be limited to the
                Dispute between the Parties individually. To the full extent
                permitted by law, (a) no arbitration shall be joined with any
                other proceeding; (b) there is no right or authority for any
                Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and (c) there is no right or authority
                for any Dispute to be brought in a purported representative
                capacity on behalf of the general public or any other persons.
              </p>
              <p class="heading-2">
                Exceptions to Informal Negotiations and Arbitration
              </p>
              <p class="text">
                The Parties agree that the following Disputes are not subject to
                the above provisions concerning informal negotiations and
                binding arbitration: (a) any Disputes seeking to enforce or
                protect, or concerning the validity of, any of the intellectual
                property rights of a Party; (b) any Dispute related to, or
                arising from, allegations of theft, piracy, invasion of privacy,
                or unauthorized use; and (c) any claim for injunctive relief. If
                this provision is found to be illegal or unenforceable, then
                neither Party will elect to arbitrate any Dispute falling within
                that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and the Parties agree to submit to the personal
                jurisdiction of that court.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="19"></a>
              <p class="heading-1">19. CORRECTIONS</p>
              <p class="text">
                There may be information on the Site that contains typographical
                errors, inaccuracies, or omissions, including descriptions,
                pricing, availability, and various other information. The
                Company reserves the right to correct any errors, inaccuracies,
                or omissions and to change or update the information on the Site
                at any time, without prior notice.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="20"></a>
              <p class="heading-1">20. DISCLAIMER</p>
              <p class="text">
                THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
                AGREE THAT YOUR USE OF THE SITE AND THE COMPANY'S SERVICES WILL
                BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
                THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, IN
                CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
                WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE
                COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE CONTENT OF
                ANY WEBSITES LINKED TO THE SITE AND THE COMPANY WILL ASSUME NO
                LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                USE OF THE COMPANY'S SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
                (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                VIA THE SITE. THE COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE,
                OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED
                OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED
                WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                BANNER OR OTHER ADVERTISING, AND THE COMPANY WILL NOT BE A PARTY
                TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
                BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
                SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH
                ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
              </p>
              <p class="heading-2">WEBSITE DISCLAIMER</p>
              <p class="text">
                The information provided by the Company on the Site is for
                general informational purposes only. All information on the Site
                is provided in good faith, however the Company makes no
                representation or warranty of any kind, express or implied,
                regarding the accuracy, adequacy, validity, reliability,
                availability or completeness of any information on the Site.
                UNDER NO CIRCUMSTANCE SHALL THE COMPANY HAVE ANY LIABILITY TO
                YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
                THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON
                THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY
                INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
              </p>
              <p class="heading-2">PROFESSIONAL DISCLAIMER</p>
              <p class="text">
                The Site cannot and does not contain resume/professional
                development advice. The resume/professional development
                information is provided for general informational and
                educational purposes only and is not a substitute for
                professional advice. Accordingly, before taking any actions
                based upon such information, the Company encourages you to
                consult with the appropriate professionals. The Company does not
                provide any kind of resume/professional development advice. THE
                USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE IS
                SOLELY AT YOUR OWN RISK.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="21"></a>
              <p class="heading-1">21. LIMITATIONS OF LIABILITY</p>
              <p class="text">
                IN NO EVENT WILL THE COMPANY OR THE COMPANY'S DIRECTORS,
                EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE,
                EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                HEREIN, THE COMPANY'S DIRECTORS LIABILITY TO YOU FOR ANY CAUSE
                WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO the
                Company DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF
                ACTION ARISING. CERTAIN the Company STATE LAWS AND INTERNATIONAL
                LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
                TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY
                NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="22"></a>
              <p class="heading-1">22. INDEMNIFICATION</p>
              <p class="text">
                You agree to defend, indemnify, and hold the Company harmless,
                including the Company's subsidiaries, affiliates, and all of the
                Company's respective officers, agents, partners, and employees,
                from and against any loss, damage, liability, claim, or demand,
                including reasonable attorneys' fees and expenses, made by any
                third party due to or arising out of: (1) use of the Site; (2)
                breach of these Terms of Use; (3) any breach of your
                representations and warranties set forth in these Terms of Use;
                (4) your violation of the rights of a third party, including but
                not limited to intellectual property rights; or (5) any overt
                harmful act toward any other user of the Site with whom you
                connected via the Site. Notwithstanding the foregoing, The
                Company reserves the right, at your expense, to assume the
                exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your
                expense, with the Company's defense of such claims. The Company
                will use reasonable efforts to notify you of any such claim,
                action, or proceeding which is subject to this indemnification
                upon becoming aware of it.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="23"></a>
              <p class="heading-1">23. USER DATA</p>
              <p class="text">
                The Company will maintain certain data that you transmit to the
                Site for the purpose of managing the performance of the Site, as
                well as data relating to your use of the Site. Although The
                Company performs regular routine backups of data, you are solely
                responsible for all data that you transmit or that relates to
                any activity you have undertaken using the Site. You agree that
                The Company shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against the Company arising from any such loss or
                corruption of such data.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="24"></a>
              <p class="heading-1">
                24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </p>
              <p class="text">
                Visiting the Site, sending the Company emails, and completing
                online forms constitute electronic communications. You consent
                to receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications The
                Company provides to you electronically, via email and on the
                Site, satisfy any legal requirement that such communication be
                in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
                ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
                TRANSACTIONS INITIATED OR COMPLETED BY the Company OR VIA THE
                SITE. You hereby waive any rights or requirements under any
                statutes, regulations, rules, ordinances, or other laws in any
                jurisdiction which require an original signature or delivery or
                retention of non-electronic records, or to payments or the
                granting of credits by any means other than electronic means.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="25"></a>
              <p class="heading-1">25. CALIFORNIA USERS AND RESIDENTS</p>
              <p class="text">
                If any complaint with the Company is not satisfactorily
                resolved, you can contact the Complaint Assistance Unit of the
                Division of Consumer Services of the California Department of
                Consumer Affairs in writing at 1625 North Market Blvd., Suite N
                112, Sacramento, California 95834 or by telephone at (800)
                952-5210 or (916) 445-1254.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="26"></a>
              <p class="heading-1">26. MISCELLANEOUS</p>
              <p class="text">
                These Terms of Use and any policies or operating rules posted by
                the Company on the Site or in respect to the Site constitute the
                entire agreement and understanding between you and the Company.
                the Company's failure to exercise or enforce any right or
                provision of these Terms of Use shall not operate as a waiver of
                such right or provision. These Terms of Use operate to the
                fullest extent permissible by law. The Company may assign any or
                all of the Company's rights and obligations to others at any
                time. The Company shall not be responsible or liable for any
                loss, damage, delay, or failure to act caused by any cause
                beyond the Company's reasonable control. If any provision or
                part of a provision of these Terms of Use is determined to be
                unlawful, void, or unenforceable, that provision or part of the
                provision is deemed severable from these Terms of Use and does
                not affect the validity and enforceability of any remaining
                provisions. There is no joint venture, partnership, employment
                or agency relationship created between you and the Company as a
                result of these Terms of Use or use of the Site. You agree that
                these Terms of Use will not be construed against the Company by
                virtue of having drafted them. You hereby waive any and all
                defenses you may have based on the electronic form of these
                Terms of Use and the lack of signing by the parties hereto to
                execute these Terms of Use.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="27"></a>
              <p class="heading-1">27. CONTACT US</p>
              <p class="text">
                In order to resolve a complaint regarding the Site or to receive
                further information regarding use of the Site, please contact
                the Company at:
              </p>
              <p class="text bold no-space">Resumation LLC</p>
              <p class="text bold no-space">25 First Ave. SW STE A</p>
              <p class="text bold no-space">Watertown, SD 57201</p>
              <p class="text bold no-space">United States</p>
              <p class="text bold no-space">Phone: (605) 229-9056</p>
              <p class="text bold no-space">
                Email:
                <a href="mailto:info@resumation.net">info@resumation.net</a>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "TermsOfUse",
  data: () => ({}),
  components: {},
};
</script>

<style>
.sub-title {
  color: var(--v-updatedText-base);
}
.heading-1 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.5rem;
}
.heading-2 {
  font-weight: 500;
  font-size: 1.25rem;
}
.heading-3 {
  font-weight: 500;
}
.upper {
  text-transform: capitalize;
}
.text {
  font-size: 1rem;
}
.bold {
  font-weight: 500;
}
.no-space {
  margin-bottom: 0px !important;
}

a.anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}
</style>
